var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main has-fixed-btn" },
    [
      _c(
        "van-tabs",
        {
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _c("van-tab", { attrs: { title: "结算单" } }, [
            _c(
              "div",
              { staticClass: "van-common-detail" },
              [
                Number(_vm.settlementForm.statementType) === 1
                  ? _c("paymentStatementPush", {
                      attrs: { "business-id": _vm.$route.query.collectionId }
                    })
                  : _c("paymentStatement", {
                      attrs: {
                        "detail-tables": _vm.settleList,
                        type: "payment"
                      }
                    })
              ],
              1
            )
          ]),
          !_vm.type
            ? _c(
                "van-tab",
                { attrs: { title: "实际付款" } },
                [
                  _c("paymentPragmatic", {
                    attrs: {
                      "detail-tables": _vm.$route.query.item.resultsList
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "van-tab",
            { attrs: { title: "相关附件" } },
            [
              !_vm.type
                ? _c(
                    "div",
                    { staticClass: "van-common-detail" },
                    [
                      _c(
                        "van-cell-group",
                        [
                          _c("van-cell", [
                            _c("p", { staticClass: "lf" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.utils.statusFormat(
                                      _vm.$route.query.item.collectionStatus,
                                      "paymentTypeList"
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("attachment-list", {
                attrs: {
                  "module-name": "PAYMENT",
                  "business-id": _vm.$route.query.collectionId,
                  "view-mode": true
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.type === "confirm"
        ? _c(
            "div",
            { staticClass: "button-fixed" },
            [
              _c(
                "van-button",
                {
                  staticClass: "button-fixed-margin",
                  attrs: { type: "info", block: "" },
                  on: { click: _vm.submit }
                },
                [_vm._v(" 确认 ")]
              ),
              _c(
                "van-button",
                {
                  staticClass: "button-fixed-margin",
                  attrs: { type: "info", block: "" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push({
                        path: "/payment/detail/appeal"
                      })
                    }
                  }
                },
                [_vm._v(" 申诉 ")]
              ),
              _c(
                "van-button",
                {
                  attrs: { type: "info", block: "" },
                  on: {
                    click: function($event) {
                      return _vm.$router.back()
                    }
                  }
                },
                [_vm._v(" 返回 ")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }