<template>
  <div class="van-main">
    <div class="van-common-detail">
      <van-cell-group>
        <van-cell>
          <p class="lf">
            {{ utils.statusFormat($route.query.item.collectionStatus, 'paymentTypeList') }}
          </p>
        </van-cell>
        <van-cell title="应付总额（元）：" :value="utils.moneyFormat($route.query.collectionAmount)" />
        <van-cell title="已付金额（元）：" :value="utils.moneyFormat($route.query.totalAmountReceived)" />
        <van-cell title="待付金额（元）：" :value="utils.moneyFormat($route.query.amountToBeReceived)" />
      </van-cell-group>
    </div>
    <div class="van-common-detail">
      <van-cell>
        <div class="goods-table">
          <table v-for="(item,idx) in $route.query.item.resultsList" :key="item.id" class="inner-goods-table" border="1">
            <tr>
              <th colspan="2" class="header-th">
                <span>{{ idx+1 }}</span>
              </th>
            </tr>
            <tr>
              <td>付款日期</td><td>{{ utils.dateFormat(item.collectionDate) }}</td>
            </tr>
            <tr>
              <td>付款方式</td><td>{{ $route.query.item.collectionType }}</td>
            </tr>
            <tr>
              <td>付款金额（元）</td><td>{{ utils.moneyFormat(item.collectionAmount) }}</td>
            </tr>
            <tr>
              <td>收款方</td><td>{{ $route.query.item.beneficiaryOrgName }}</td>
            </tr>
            <tr>
              <td>付款方账号</td><td>{{ $route.query.item.paymentAccount }}</td>
            </tr>
            <tr>
              <td>备注</td><td>{{ item.remarks }}</td>
            </tr>
            <tr>
              <td>附件</td>
              <td>
                <div v-for="(item, index) in item.fileDetailInfoVoList" :key="index">
                  <a download :href="_.get(item, 'fileUrl')">{{ _.get(item, 'fileName', '-') }}</a>
                </div>
              </td>
            </tr>
            <tr>
              <td>状态</td><td>{{ utils.statusFormat(item.resultStatus, 'resultStatus') }}</td>
            </tr>
          </table>
        </div>
      </van-cell>
    </div>
  </div>
</template>

<script>
import { List, PullRefresh, Cell, CellGroup, Search, Button, Field, Form, Col, Row, Tab, Tabs } from 'vant'
export default {
  name: 'AttachmentList',
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Field.name]: Field,
    [Form.name]: Form,
    [Col.name]: Col,
    [Row.name]: Row
  },
  props: {
    // 所属模块
    detailTables: {
      type: [Array],
      default () {
        return []
      }
    }
  },
  data () {
    return {
      appForm: {
        fileName: '',
        pageNum: 1,
        pageSize: 10,
        moduleName: ''
      },
      loading: false,
      finished: false,
      appDownList: [],
      amountPaid: 0
    }
  },
  created () {
  },
  methods: {

  }
}
</script>

<style lang="less">
</style>
