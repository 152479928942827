<template>
  <div class="van-main has-fixed-btn">
    <van-tabs v-model="active">
      <van-tab title="结算单">
        <div class="van-common-detail">
          <paymentStatementPush v-if="Number(settlementForm.statementType) === 1" :business-id="$route.query.collectionId" />
          <paymentStatement v-else :detail-tables="settleList" type="payment" />
        </div>
      </van-tab>
      <van-tab v-if="!type" title="实际付款">
        <paymentPragmatic :detail-tables="$route.query.item.resultsList" />
      </van-tab>
      <van-tab title="相关附件">
        <div v-if="!type" class="van-common-detail">
          <van-cell-group>
            <van-cell>
              <p class="lf">
                {{ utils.statusFormat($route.query.item.collectionStatus, 'paymentTypeList') }}
              </p>
            </van-cell>
          </van-cell-group>
        </div>
        <attachment-list module-name="PAYMENT" :business-id="$route.query.collectionId" :view-mode="true" />
      </van-tab>
    </van-tabs>
    <div v-if="type==='confirm'" class="button-fixed">
      <van-button type="info" class="button-fixed-margin" block @click="submit">
        确认
      </van-button>
      <van-button type="info" class="button-fixed-margin" block @click="$router.push({path:'/payment/detail/appeal'})">
        申诉
      </van-button>
      <van-button type="info" block @click="$router.back()">
        返回
      </van-button>
    </div>
  </div>
</template>

<script>
import { Cell, CellGroup, Divider, Button, Tab, Tabs, Toast, uploader } from 'vant'
import paymentStatement from '@/components/payment/payment-statement'
import paymentPragmatic from '@/components/payment/payment-pragmatic'
import AttachmentList from '@/components/attachment-list'
import paymentStatementPush from '@/components/payment/payment-pragmatic-push'
// import fileList from '@/components/file-list'
export default {
  components: {
    paymentStatement,
    paymentPragmatic,
    AttachmentList,
    paymentStatementPush,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Toast.name]: Toast,
    [uploader.name]: uploader
    // fileList
  },
  data () {
    return {
      id: this.$route.query.id,
      // 判断是否是代办来的
      type: this.$route.query.type,
      detailId: this.$route.query.detailId,
      active: 0,
      settlementForm: {},
      contractForm: {
        signInfoList: [],
        fileDetailInfoVoApply: null,
        fileDetailInfoVoSeal: null
      },
      fileList: [],
      appDownList: [],
      settleList: {
        name: '1111'
      }
    }
  },
  computed: {
  },
  created () {
    this.getStatementDetail()
  },
  methods: {
    // 代办确认
    submit () {
      this.api.base.listUpdate(this.detailId).then(result => {
        Toast.success(result.data.message || '确认成功')
        this.$router.back()
      }).catch(e => {
      })
    },
    getStatementDetail () {
      this.api.collection.statementDetail({ businessid: this.$route.query.collectionId }).then(result => {
        this.settlementForm = this._.assign(this.settlementForm, result.data.value)
      }).finally(() => {
      })
    }
  }
}
</script>

<style scoped>
  .van-main{
    margin-bottom: 130px !important;
  }
</style>
