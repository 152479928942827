var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "van-main" }, [
    _c(
      "div",
      { staticClass: "van-common-detail" },
      [
        _c(
          "van-cell-group",
          [
            _c("van-cell", [
              _c("p", { staticClass: "lf" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.utils.statusFormat(
                        _vm.$route.query.item.collectionStatus,
                        "paymentTypeList"
                      )
                    ) +
                    " "
                )
              ])
            ]),
            _c("van-cell", {
              attrs: {
                title: "应付总额（元）：",
                value: _vm.utils.moneyFormat(_vm.$route.query.collectionAmount)
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "已付金额（元）：",
                value: _vm.utils.moneyFormat(
                  _vm.$route.query.totalAmountReceived
                )
              }
            }),
            _c("van-cell", {
              attrs: {
                title: "待付金额（元）：",
                value: _vm.utils.moneyFormat(
                  _vm.$route.query.amountToBeReceived
                )
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "van-common-detail" },
      [
        _c("van-cell", [
          _c(
            "div",
            { staticClass: "goods-table" },
            _vm._l(_vm.$route.query.item.resultsList, function(item, idx) {
              return _c(
                "table",
                {
                  key: item.id,
                  staticClass: "inner-goods-table",
                  attrs: { border: "1" }
                },
                [
                  _c("tr", [
                    _c(
                      "th",
                      { staticClass: "header-th", attrs: { colspan: "2" } },
                      [_c("span", [_vm._v(_vm._s(idx + 1))])]
                    )
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("付款日期")]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.utils.dateFormat(item.collectionDate)))
                    ])
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("付款方式")]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.$route.query.item.collectionType))
                    ])
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("付款金额（元）")]),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.utils.moneyFormat(item.collectionAmount))
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("收款方")]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.$route.query.item.beneficiaryOrgName))
                    ])
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("付款方账号")]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.$route.query.item.paymentAccount))
                    ])
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("备注")]),
                    _c("td", [_vm._v(_vm._s(item.remarks))])
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("附件")]),
                    _c(
                      "td",
                      _vm._l(item.fileDetailInfoVoList, function(item, index) {
                        return _c("div", { key: index }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                download: "",
                                href: _vm._.get(item, "fileUrl")
                              }
                            },
                            [_vm._v(_vm._s(_vm._.get(item, "fileName", "-")))]
                          )
                        ])
                      }),
                      0
                    )
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("状态")]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.statusFormat(
                            item.resultStatus,
                            "resultStatus"
                          )
                        )
                      )
                    ])
                  ])
                ]
              )
            }),
            0
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }